import { ThemeProvider } from 'react-bootstrap';
import RoutesLink from './Routes';
import '../styles/App.css';

function App() {
  return (
    <ThemeProvider
      breakpoints={['xxxl', 'xxl', 'xl', 'lg', 'md', 'sm', 'xs', 'xxs']}
    >
      <div>
        <RoutesLink />
      </div>
    </ThemeProvider>
  );
}

export default App;
