import React from 'react';
import {
  Container, Row, Col, Image,
} from 'react-bootstrap';
import Harvey from '../assets/abogados.jpeg';

function Abogados() {
  return (
    <Container style={{ backgroundColor: '#f8f8f8' }} fluid>
      <Row>
        <Col className="nosotros mt-5" sm md lg xl xxl xxxl>
          <Image
            className="pl-3"
            src={Harvey}
            alt="Diamond Consultoria Migratoria"
            style={{
              width: '100%',
              height: 'auto',
              borderRadius: '25px',
              marginBottom: '1rem',
            }}
            fluid
          />
        </Col>
        <Col className="nosotros mt-5" sm md lg xl xxl xxxl>
          <p>
            Nuestro trabajo como abogados  especialista es ayudar al extranjero
            en cualquier problema legal que pueda surgir mientras este en Repùblica Dominicana.
            Algunos ejemplos de los problemas legales en que podemos asesorarte se
            muestra a continuación:
          </p>
          <ul>
            <li>
              Ser imputado  por las autoridades  penales federales o locales para supuesto delitos.
            </li>
            <li>
              Cuando sea amenazado por una persona con una demanda por incumplimiento
              de contrato o de cualquier otra causa
            </li>
            <li>
              Frente a un procedimiento agrario por un ejido local debido a una disputa de propiedad
              de la tierra.
            </li>
            <li>
              Créditos fiscales impuestos a los extranjeros, debido a supuestas omisiones en sus
              obligaciones fiscales.
            </li>
            <li>
              La discriminación  y la eliminación de los recursos  interpuestos  por
              las autoridades de migración.
            </li>
            <li>
              Terminación injustificada del empleo (despido), el acoso sexual en su
              lugar de trabajo,
              controversias diferencias laborales.
            </li>
            <li>
              Accidentes y los casos de lesiones personales.
            </li>
          </ul>
        </Col>
      </Row>
    </Container>
  );
}

export default Abogados;
