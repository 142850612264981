import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Carousel, Image, Button } from 'react-bootstrap';
import AOS from 'aos';
import 'aos/dist/aos.css';
import RevSlider from '../utility/carousel.json';

function CarouselSlider() {
  const [index, setIndex] = useState(0);

  useEffect(() => {
    AOS.init();
  });

  const handleSelect = (selectedIndex, e) => {
    e.preventDefault();
    setIndex(selectedIndex);
  };

  return (
    <Carousel activeIndex={index} onSelect={handleSelect} interval={1000} className="carousel-slider">
      { RevSlider && RevSlider.map((sl) => (
        <Carousel.Item key={sl.id} interval={5000}>
          <div
            data-aos="zoom-in"
            data-aos-duration="3000"
          >
            <Image
              src={sl.image}
              alt="Diamond Consultoria Migratoria"
              className="carousel-image"
              style={{ width: '100%', height: '100vh' }}
              fluid
            />
          </div>
          <div className="caption">
            <Carousel.Caption>
              <h1 className="title">{sl.title}</h1>
              <Button variant="dark" className="carousel-button">
                <Link to="/contacto">CONTÁCTENOS</Link>
              </Button>
            </Carousel.Caption>
          </div>
        </Carousel.Item>
      ))}
    </Carousel>
  );
}

export default CarouselSlider;
