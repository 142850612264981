import React from 'react';
import { Link } from 'react-router-dom';
import {
  Container, Navbar, Nav, NavDropdown,
} from 'react-bootstrap';
import Image from 'react-bootstrap/Image';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFacebook, faInstagram, faLinkedin, faTiktok, faYoutube,
} from '@fortawesome/free-brands-svg-icons';
// import TopNav from './TopNav';
import Logo from '../assets/logo.png';

function NavBar() {
  return (
    <>
      {/* <TopNav /> */}
      <Navbar collapseOnSelect expand="lg" bg="transparent" variant="dark">
        <Container>
          <Navbar.Brand href="#home">
            <Link to="/">
              <Image src={Logo} className="logo" alt="Diamond Consultoria Migratoria" fluid />
            </Link>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="me-auto">
              <Nav.Link href="#features">
                <Link to="/" className="navlink">
                  INICIO
                </Link>
              </Nav.Link>
              <NavDropdown title="SERVICIOS" id="collasible-nav-dropdown" className="navlink">
                <NavDropdown.Item href="#litigio-migratorio">
                  <Link to="/litigio" className="navlink">
                    Litigio Migratorio
                  </Link>
                </NavDropdown.Item>
                <NavDropdown.Item href="#mundandose">
                  <Link to="/mundandose" className="navlink">
                    Mudándose a República Dominicana
                  </Link>
                </NavDropdown.Item>
                <NavDropdown.Item href="#apostillas">
                  <Link to="/apostillas" className="navlink">
                    Apostillas Legalizaciones y Traducciones
                  </Link>
                </NavDropdown.Item>
                <NavDropdown.Item href="#residencia">
                  <Link to="/residencia" className="navlink">
                    Residencia
                  </Link>
                </NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item href="#impuestos">
                  <Link to="/impuestos" className="navlink">
                    Impuestos, Corporativos, Negocios y Bienes Raíces
                  </Link>
                </NavDropdown.Item>
                <NavDropdown.Item href="#asuntos-legales">
                  <Link to="/asuntos-legales" className="navlink">
                    Otros asuntos legales
                  </Link>
                </NavDropdown.Item>
              </NavDropdown>
              <Nav.Link href="#nosotros">
                <Link to="/nosotros" className="navlink">
                  NOSOTROS
                </Link>
              </Nav.Link>
              <Nav.Link href="#contacto">
                <Link to="/contacto" className="navlink">
                  CONTACTO
                </Link>
              </Nav.Link>
            </Nav>
            <Nav>
              <a
                href="https://www.facebook.com/diamondconsultoriamigratoria/"
                rel="noopener noreferrer"
                target="_blank"
              >
                <FontAwesomeIcon icon={faFacebook} className="icons" size="2xl" />
              </a>
              <a
                href="https://www.instagram.com/diamondmigratorio/"
                rel="noopener noreferrer"
                target="_blank"
              >
                <FontAwesomeIcon icon={faInstagram} className="icons" size="2xl" />
              </a>
              <a
                href="https://www.linkedin.com/in/diamond-consultoria-migratoria-1634a4243/"
                rel="noopener noreferrer"
                target="_blank"
              >
                <FontAwesomeIcon icon={faLinkedin} className="icons" size="2xl" />
              </a>
              <a
                href="https://www.tiktok.com/@diamondconsultoriamigrat"
                rel="noopener noreferrer"
                target="_blank"
              >
                <FontAwesomeIcon icon={faTiktok} className="icons" size="2xl" />
              </a>
              <a
                href="https://www.youtube.com/watch?v=HPW14WyJuBw"
                rel="noopener noreferrer"
                target="_blank"
              >
                <FontAwesomeIcon icon={faYoutube} className="icons" size="2xl" />
              </a>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
}

export default NavBar;
