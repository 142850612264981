import React from 'react';
import {
  Container, Col, Row,
} from 'react-bootstrap';

function Compromiso() {
  return (
    <Container>
      <h1 className="heading">NUESTRO COMPROMISO</h1>
      <hr className="divider" />
      <Row>
        <Col className="intro" sm md lg xl xxl>
          Sabemos que los trámites migratorios en República Dominicana pueden ser estresantes
          y frustrantes. Nuestra meta es que no tenga estrés y ayudarle con su trámite migratorio
          de la manera más rápida y fácil posible,
          para que pueda lograr sus metas:
          <ul>
            <li>Escuchamos sus necesidades para identificar sus planes y brindarle soluciones.</li>
            <li>Le informamos sobre las opciones y el proceso específico que decida seguir.</li>
            <li>
              Le damos expectativas reales y tiempos estimados y nos aseguramos que esté
              informado en todo momento del estatus de su trámite migratorio
            </li>
            <li>
              Tarifas y honorarios claros desde el principio – no nos gustan las sorpresas
              y costos escondidos
            </li>
            <li>Anticipamos sus necesidades y siempre buscamos atender sus inquietudes y dudas</li>
            <li>Cumplimos con nuestros compromisos con usted</li>
          </ul>
          Contáctenos para agendar una consulta con uno de nuestros asesores.
          ¡Esperamos ayudarle a lograr sus metas en República Dominicana!
        </Col>
      </Row>
    </Container>
  );
}

export default Compromiso;
