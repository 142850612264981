/* eslint-disable no-alert */
/* eslint-disable no-undef */
/* eslint-disable no-use-before-define */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/no-unescaped-entities */
import React, { useState } from 'react';
import {
  Container, Row, Col, Card, Form, Button,
} from 'react-bootstrap';
import Logo from '../assets/logo.png';

function FormCitas() {
  const [userData, setUserData] = useState({
    pasaporte: '',
    ubicacion: '',
    telefono: '',
    correo: '',
    fuente: '',
    ingreso: '',
    entegra: '',
    visa: '',
    emision: '',
    vencimiento: '',
  });

  const handleChange = (e) => {
    setUserData({
      ...userData,
      [e.target.name]: e.target.value,
    });
    uploadFiles(file);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const {
      pasaporte,
      ubicacion,
      telefono,
      correo,
      fuente,
      ingreso,
      entegra,
      visa,
      emision,
      vencimiento,
    } = userData;
    const response = await fetch('https://diamond-consultoria-migratoria-default-rtdb.firebaseio.com/userDataRecords.json', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        pasaporte,
        ubicacion,
        telefono,
        correo,
        fuente,
        ingreso,
        entegra,
        visa,
        emision,
        vencimiento,
      }),
    });

    if (response) {
      setUserData({
        pasaporte,
        ubicacion,
        telefono,
        correo,
        fuente,
        ingreso,
        entegra,
        visa,
        emision,
        vencimiento,
      });
      alert('Ahora necesitamos la foto de la primera pagina de tu pasaporte para terminar');
    } else {
      alert('Necesitamos las informaciones para agendar una cita');
    }
  };

  return (
    <Container>
      <Row>
        <Col
          className="form-container mt-2"
          sm
          md
          lg
          xl
          xxl
        >
          <Card className="form-card">
            <Card.Body>
              <Card.Img src={Logo} alt="Diamond Consultoria Migratoria" style={{ width: '10rem' }} />
              <br />
              <br />
              <Card.Title>
                DIAMOND CONSULTORIA MIGRATORIA
              </Card.Title>
              <Card.Text>
                "Simplificando el derecho migratorio"
                <br />
                RNC: 132-301-048
                <br />
                <b>Formulario de reservacion</b>
              </Card.Text>
              <hr />
              <Card.Text>
                <Form onSubmit={handleSubmit}>
                  <Form.Group className="mb-3" controlId="formBasicAddress">
                    <Form.Label>Numero de pasaporte</Form.Label>
                    <Form.Control
                      type="text"
                      name="pasaporte"
                      placeholder="# Pasaporte"
                      value={userData.pasaporte}
                      onChange={handleChange}
                      required
                    />
                    <Form.Label>Ubicacion</Form.Label>
                    <Form.Control
                      type="text"
                      name="ubicacion"
                      placeholder="Ubicacion"
                      value={userData.ubicacion}
                      onChange={handleChange}
                      required
                    />
                    <Form.Label>Telefono</Form.Label>
                    <Form.Control
                      type="text"
                      name="telefono"
                      placeholder="Telefono"
                      value={userData.telefono}
                      onChange={handleChange}
                      required
                    />
                    <Form.Label>Correo Electronico</Form.Label>
                    <Form.Control
                      type="email"
                      name="correo"
                      placeholder="joe@joe.me"
                      value={userData.correo}
                      onChange={handleChange}
                    />
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="formBasicIngreso">
                    <Form.Label>Fuente de engreso</Form.Label>
                    <Form.Control
                      type="text"
                      name="fuente"
                      placeholder="Fuente de engreso"
                      value={userData.fuente}
                      onChange={handleChange}
                      required
                    />
                    <Form.Select placeholder="Ingreso" name="ingreso" value={userData.ingreso} onChange={handleChange} required>
                      <option>Ingreso mensual en dolares americano</option>
                      <option value="$0 - $300USD">$0 - $300 USD</option>
                      <option value="$301 - $500 USD">$301 - $500 USD</option>
                      <option value="$501 - $1000 USD">$501 - $1000 USD</option>
                      <option value="$1001 - $2000 USD">$1001 - $2000 USD</option>
                      <option value="$2001 - $5000 USD">$2001 - $5000 USD</option>
                      <option value="$5001 - ">$5001 - </option>
                    </Form.Select>
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="formBasicImigracion">
                    <Form.Label>Fecha de entrada</Form.Label>
                    <Form.Control type="date" name="entegra" value={userData.entegra} onChange={handleChange} required />
                    <Form.Label>Tipo de visa</Form.Label>
                    <Form.Select placeholder="Visa" name="visa" value={userData.visa} onChange={handleChange} required>
                      <option>Elige tu tipo de visa</option>
                      <option value="Visa de Turismo">Visa de Turismo</option>
                      <option value="Visa de Estudiante">Visa de Estudiante</option>
                      <option value="Visa de Trabajo">Visa de Trabajo</option>
                      <option value="Visa de Residencia">Visa de Residencia</option>
                      <option value="Visa de Inversion">Visa de Inversion</option>
                    </Form.Select>
                    <Form.Label>Fecha de emision de la visa</Form.Label>
                    <Form.Control type="date" name="emision" value={userData.emision} onChange={handleChange} required />
                    <Form.Label>Fecha de vencimiento de la visa</Form.Label>
                    <Form.Control type="date" name="vencimiento" value={userData.vencimiento} onChange={handleChange} required />
                  </Form.Group>

                  <Button variant="primary" type="submit">
                    Enviar
                  </Button>
                </Form>
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default FormCitas;
