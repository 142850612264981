import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import FormRaices from '../components/FormRaices';

function Raices() {
  return (
    <Container style={{ backgroundColor: '#f8f8f8' }} fluid>
      <h1 className="heading">Bien Raices</h1>
      <hr className="divider" />
      <Row>
        <Col>
          <FormRaices />
        </Col>
      </Row>
    </Container>
  );
}

export default Raices;
