import React from 'react';
import {
  Container, Row, Col,
} from 'react-bootstrap';

function Legal() {
  return (
    <Container>
      <Row>
        <Col className="contact text-center mt-5" sm md lg xl xxl xxxl>
          <p>
            Te ayudamos a entender las leyes y demás disposiciones legales, juicios
            y procedimientos en República Dominicana, así como también te guiamos paso
            a paso en tus asuntos legales.
            El sistema legal de República Dominicana se basa en el derecho civil, a diferencia
            de sus contrapartes de derecho consuetudinario. Por lo tanto, la ley dominicana
            tiende a estar altamente codificada. Sin embargo, las reformas y modificaciones
            a las leyes se llevan a cabo continuamente (especialmente en áreas dinámicas del
            derecho como el derecho tributario y la propiedad intelectual).
          </p>
        </Col>
      </Row>
    </Container>
  );
}

export default Legal;
