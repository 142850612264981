import React from 'react';
import {
  Container, Row, Col, Image,
} from 'react-bootstrap';
import Asesor from '../assets/assessor.jpg';

function AboutUs() {
  return (
    <Container className="about-us mt-5">
      <Row>
        <Col className="nosotros" sm md lg xl xxl>
          Sabemos que estar en un país extranjero puede tener un fuerte impacto en nuestras vidas.
          Es por esto que necesitas la asesoría de un abogado especialista en trámites migratorios
          (Abogados Migratorios) para que te ayude con tu proceso migratorio
          en República Dominicana.
          <br />
          <br />
          Asi mismo, en el contexto del mundo digital y globalizado, las personas y empresas
          mantienen intercambios comerciales y desarrollan actividades que trascienden fronteras.
          En otras latitudes se persiguen intereses económicos, sociales e incluso personales y
          precisamente estos intereses nos han impulsado a ofrecer servicios de asesoría jurídica.
          De manera similar, los impactos de los movimientos políticos, las guerras comerciales,
          los riesgos para la salud o incluso la competencia económica (por nombrar solo algunos
          de los desafíos del mundo globalizado) obligan a las personas y sus familias a buscar en
          otros países opciones que les permitan vivir en armonía. Incluso las empresas y los
          empresarios pueden tratar de llegar más allá de sus fronteras para desarrollar sus
          intereses comerciales.
          <br />
          <br />
          Es por esto que la práctica de nuestra firma se ha sofisticado, ofreciendo servicios
          de asesoría y asesoría legal en materia de extranjería y tributación internacional,
          basados en el análisis de tratados internacionales en materia tributaria y legislación
          internacional en materia de extranjería para el diseño de acuerdos a la medida.
          estrategias hechas. las necesidades de nuestros clientes.
        </Col>
        <Col className="nosotros image-home" sm md lg xl xxl>
          <Image
            src={Asesor}
            alt="Diamond Consultoria Migratoria"
            style={{
              width: '70%',
              height: 'auto',
              borderRadius: '25px',
              marginBottom: '1rem',
              marginLeft: '4rem',
            }}
            fluid
          />
        </Col>
      </Row>
    </Container>
  );
}

export default AboutUs;
