import React from 'react';
import {
  Container, Col, Row,
} from 'react-bootstrap';

function Contacto() {
  return (
    <Container>
      <Row>
        <Col className="contacto text-center" sm md lg xl xxl>
          <h6 className="mt-5">
            Si tiene alguna duda o desea mayor información sobre migrar o trabajar en la
            Republica Dominicana y desea que Consultoría Migratoria Abogados le ayude,
            <br />
            o desea
            asesoría legal en materia migratoria, residencia, ciudadanía, defensa migratoria,
            inversión en Republica Dominicana y cualquier otra inquietud legal,
            <br />
            puede contactarnos usando la
            forma de contacto o bien el número de teléfono que aparece abajo.
            <br />
            También nos puede
            visitar previa cita a nuestra Firma, en la siguiente dirección:
          </h6>
          <br />
          <h6>
            Telefono:
            <br />
            <br />
            +1 829 489-4441
          </h6>
          <br />
          <h6>
            Dirección:
            <br />
            <br />
            353, Ave Simon Bolivar, Edif Profesional ELIAMS II, Suite 2G, Gazcue, DN,
            Santo Domingo, RD.
          </h6>
        </Col>
      </Row>
    </Container>
  );
}
export default Contacto;
