import React from 'react';
import {
  Container, Row, Col, Image,
} from 'react-bootstrap';
import Logo from '../assets/logo.png';

function TopFooter() {
  return (
    <Container>
      <Row>
        <Col className="footer-content mt-5">
          <Image
            src={Logo}
            style={{ width: '10rem', height: 'auto' }}
            alt="Diamond Consultoria Migratoria"
            fluid
          />
          <br />
          <br />
          Abogados de migración
          <br />
          <br />
          353, Ave Simon Bolivar, Edif Profesional ELIAMS II, Suite 2G, Gazcue,
          DN, Santo Domingo, RD.
          <br />
          <br />
          Telefono : +1 829 489-4441 (Whatsapp)
          <br />
          <br />
          email: info@diamondlawyersdr.com
        </Col>
        <Col className="footer-content mt-5">
          <h4>HORARIO DE ATENCIÓN</h4>
          Atendemos todas las llamadas y mensajes en los siguientes horarios:
          <br />
          <br />
          Lunes a Viernes de 10:00 a 15:00 hrs (Horario de STDO)
        </Col>
        <Col className="footer-content mt-5">
          <h4>ATENCIÓN PERSONAL EN LA FIRMA</h4>
          Por motivos de seguridad es necesario siempre concertar entrevista previa
          por mail o telefónicamente.
          <br />
          <br />
          Sin entrevista previa no será permitido el acceso.
        </Col>
      </Row>
    </Container>
  );
}

export default TopFooter;
