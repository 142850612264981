import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import FormCitas from '../components/FormCitas';
import UploadFiles from '../components/UploadFiles';

function Citas() {
  return (
    <Container style={{ backgroundColor: '#f8f8f8' }} fluid>
      <h1 className="heading">Agendar una Cita</h1>
      <hr className="divider" />
      <Row>
        <Col>
          <FormCitas />
          <br />
        </Col>
        <Col>
          <UploadFiles />
          <br />
        </Col>
      </Row>
    </Container>
  );
}

export default Citas;
