import React from 'react';
import {
  Container, Row, Col,
} from 'react-bootstrap';
import Video from '../components/VideoFrame';
import AboutUs from '../components/AboutUs';

function About() {
  return (
    <Container style={{ backgroundColor: '#f8f8f8' }} fluid>
      <h1 className="text-center mt-5">Nosotros</h1>
      <hr className="divider" />
      <Row>
        <Col className="nosotros" sm md lg xl xxl>
          <Video />
        </Col>
      </Row>
      <Row>
        <Col className="nosotros" sm md lg xl xxl>
          <AboutUs />
        </Col>
      </Row>
    </Container>
  );
}

export default About;
