import React from 'react';
import {
  Container, Row, Col,
} from 'react-bootstrap';

function Litigio() {
  return (
    <Container>
      <h1 className="text-center mt-5">LITIGIO MIGRATORIO</h1>
      <hr className="divider" />
      <Row>
        <Col className="nosotros" sm md lg xl xxl xxxl>
          Todas las personas tienen derechos consagrados en la Constitución Política de la
          República Dominicana, incluidos los extranjeros. Sin embargo, en algunas ocasiones,
          las autoridades migratorias pueden dictar una resolución que afecte los derechos de
          los extranjeros; en algunos casos, el demandante no está de acuerdo con el sentido de
          la resolución. Por ello, la legislación dominicana prevé diversas vías de defensa para
          impugnar el sentido de las resoluciones administrativas, ya sea a través de los
          procedimientos de revisión, de nulidad o de amparo. Nuestra firma ha acumulado
          experiencia en litigio contra las resoluciones administrativas de las autoridades
          migratorias, mediante la implementación de estrategias jurídicas para la defensa de
          los derechos e intereses de nuestros clientes, logrando la nulidad de las resoluciones
          emitidas por el Instituto Nacional de Migración, sanciones que constituyen precedentes y
          podrán ser invocados en los trámites y trámites migratorios que se realicen
          ante dicho Instituto.
        </Col>
      </Row>
      <br />
      <br />
      <Row>
        <Col className="nosotros pb-5" sm md lg xl xxl xxxl>
          En este sentido, nuestros abogados de extranjería pueden promover la defensa jurídica
          frente a cualquier resolución emitida por el Instituto Nacional de Migración que vulnere
          los derechos de los extranjeros. Para ello, nuestra firma realizará un análisis de la
          resolución del Instituto Nacional de Migración de emitir dictamen jurídico, determinando
          la viabilidad de una defensa jurídica eficaz y eficiente. Una vez emitida la opinión,
          nuestra Firma discutirá con usted la estrategia legal a seguir, para que tenga los
          elementos para tomar una decisión informada. Asimismo, a los extranjeros se les garantiza
          la salud, la educación y el estado civil en Republica Dominicana.
        </Col>
      </Row>
    </Container>
  );
}

export default Litigio;
