import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

function Intro() {
  return (
    <Container>
      <h1 className="heading">ABOGADOS MIGRATORIOS | TRÁMITES MIGRATORIOS EN REPUBLICA DOMINICANA</h1>
      <hr className="divider" />
      <Row>
        <Col className="cover" sm md lg xl xxl>
          Sabemos que estar en un país extraño puede tener un fuerte impacto en nuestras vidas.
          Hemos estado ahí. Es por eso que necesita la asesoría de un abogado especialista en
          trámites migratorios (Abogados Migratorios)
          para poder ayudarle con sus asuntos migratorios en República Dominicana.
          <br />
          <br />
          De igual manera, en el contexto del mundo digital y globalizado, las personas y las
          empresas mantienen intercambios comerciales y desarrollan actividades que trascienden
          fronteras. Los intereses económicos, sociales e incluso personales son perseguidos en
          otras latitudes y justamente dichos intereses nos han impulsado an ofrecer servicios de
          asesoría legal.
          <br />
          <br />
          Asimismo, los impactos de los movimientos políticos, guerras comerciales,
          contingencias sanitarias o incluso la competencia económica (por mencionar algunos
          desafíos del mundo globalizado) obligan a las personas y sus familias a buscar opciones
          en otros países que les permitan vivir armónicamente. Incluso las empresas y
          emprendedores pueden buscar ir más allá de sus fronteras para el desarrollo de sus
          intereses comerciales.
          <br />
          <br />
          Es por ello que la práctica de nuestra Firma se ha sofisticado,
          ofreciendo servicios de asesoría y consultoría legal en materia migratoria e impuestos
          internacionales, partiendo del análisis de los tratados internacionales en materia
          tributaria y la legislación migratoria internacional para el diseño de estrategias hechas
          a la medida de las necesidades de nuestros clientes.
        </Col>
      </Row>
    </Container>
  );
}

export default Intro;
