import React from 'react';
import {
  Container, Row, Col, Image,
} from 'react-bootstrap';
import Legalizeit from '../assets/legal.jpeg';

function Legalizacion() {
  return (
    <Container style={{ backgroundColor: '#f8f8f8' }} fluid>
      <Row>
        <Col className="nosotros mt-5" sm md lg xl xxl xxxl>
          <Image
            className="pl-3"
            src={Legalizeit}
            alt="Diamond Consultoria Migratoria"
            style={{
              width: '100%',
              height: 'auto',
              borderRadius: '25px',
              marginBottom: '1rem',
            }}
            fluid
          />
        </Col>
        <Col className="nosotros mt-5" sm md lg xl xxl xxxl>
          <h2 className="text-center text-uppercase pr-3">Legalización</h2>
          <p>
            La legislación mexicana prevé que para que un documento publico extranjero
            surta efecto en Repùblica Dominicana, es necesario que se presente debidamente
            legalizado por
            a representación consultar mexicana ubicada en el lugar en el que se expidió el
            documento legalizado . Cabe señalar  que deberán legalizarse todos aquellos documentos
            publico emitidos por países que no sean miembros de la convención de Haya sobre la
            Apostilla y que deban utilizarse en otro país no miembro de dicha convención,
            el primero debe de legalizar su documento, igualmente se realiza el procedimiento a
            la inversa. La legalización de firmas y /o sellos, contenidos en documentos público
            extranjeros, es un acto de certificación por medio del cual el funcionario consular
            certifica que la firma o sello (de un funcionario u oficina gubernamental o notarial)
            que se localiza dentro de su circunscripción, coincide con la forma o sello registrados
            en sus archivos, Al igual que en el caso de la apostilla, esta certificación nunca habrá
            de prejuzgar sobre el contenido del documento.
          </p>
        </Col>
      </Row>
    </Container>
  );
}

export default Legalizacion;
