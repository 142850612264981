/* eslint-disable import/prefer-default-export */
/* eslint-disable no-unused-vars */
// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getStorage } from 'firebase/storage';
import { getAnalytics } from 'firebase/analytics';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyCcAu5_41OofA20jmhwjtiv4L3UxLe8p14',
  authDomain: 'diamond-consultoria-migratoria.firebaseapp.com',
  databaseURL: 'https://diamond-consultoria-migratoria-default-rtdb.firebaseio.com',
  projectId: 'diamond-consultoria-migratoria',
  storageBucket: 'diamond-consultoria-migratoria.appspot.com',
  messagingSenderId: '759609166687',
  appId: '1:759609166687:web:ee8c395685f565708784ea',
  measurementId: 'G-VWM2D5DX3H',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const storage = getStorage(app);
const analytics = getAnalytics(app);
