import React from 'react';
import { Link } from 'react-router-dom';
import {
  Container, Row, Col, Button,
} from 'react-bootstrap';

function Agendar() {
  return (
    <Container>
      <Row>
        <Col className="mt-5 text-center">
          <h6>
            Para agendar una consulta con uno de nuestros especialistas,
            puede dar click en el siguiente enlace,
            donde se desplegará nuestro calendario con los espacios disponibles.
            La consulta tiene costo (mismo que será informado mediante correo
            electrónico al momento de su confirmación).
          </h6>
          <br />
          <br />
          <Button variant="dark" className="citas-button">
            <Link to="/citas">AGENDAR CONSULTA</Link>
          </Button>
          <br />
          <br />
          <h6>
            La consulta es importante para nosotros, pues nos permitirá contar con la mejor
            información disponible sobre su situación, valorar correctamente su caso e informarle
            las estrategias legales que tiene.
          </h6>
          <br />
          <br />
          <h6>
            Sin tener toda la información pertinente de su caso,
            podemos darle información verdadera pero quizá no sea relevante para su caso.
          </h6>
          <br />
          <br />
        </Col>
      </Row>
    </Container>
  );
}

export default Agendar;
