import React from 'react';
import {
  MapContainer, TileLayer, Marker, Popup,
} from 'react-leaflet';
import 'leaflet/dist/leaflet.css';

const center = [18.46916411491764, -69.9048189711649];

function Geolocation() {
  return (
    <MapContainer center={center} zoom={13} scrollWheelZoom={false}>
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      <Marker position={center}>
        <Popup>
          Diamond Consultoria Migratoria S.R.L
          {' '}
          <br />
          {' '}
          La ley a tu lado por siempre
        </Popup>
      </Marker>
    </MapContainer>
  );
}

export default Geolocation;
