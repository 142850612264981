/* eslint-disable no-alert */
/* eslint-disable no-undef */
/* eslint-disable no-use-before-define */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/no-unescaped-entities */
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Container, Row, Col, Card, Form, Button,
} from 'react-bootstrap';
import Logo from '../assets/logo.png';

function FormCitas() {
  const navigate = useNavigate();
  const [userData, setUserData] = useState({
    nombre: '',
    telefono: '',
    correo: '',
    ubicacion: '',
    propriedad: '',
    precio: '',
    necesidad: '',
    habitacion: '',
    bano: '',
  });

  const handleChange = (e) => {
    setUserData({
      ...userData,
      [e.target.name]: e.target.value,
    });
    uploadFiles(file);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const {
      nombre,
      telefono,
      correo,
      ubicacion,
      propriedad,
      precio,
      necesidad,
      habitacion,
      bano,
    } = userData;
    const response = await fetch('https://diamond-consultoria-migratoria-default-rtdb.firebaseio.com/raicesDataRecords.json', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        nombre,
        telefono,
        correo,
        ubicacion,
        propriedad,
        precio,
        necesidad,
        habitacion,
        bano,
      }),
    });

    if (response) {
      setUserData({
        nombre,
        telefono,
        correo,
        ubicacion,
        propriedad,
        precio,
        necesidad,
        habitacion,
        bano,
      });
      alert('Informacion cargada con exito, gracias!');
      navigate('/', { replace: true });
    } else {
      alert('Por favor completa el formulario');
    }
  };

  return (
    <Container>
      <Row>
        <Col
          className="form-container mt-2"
          sm
          md
          lg
          xl
          xxl
        >
          <Card className="form-card">
            <Card.Body>
              <Card.Img src={Logo} alt="Diamond Consultoria Migratoria" style={{ width: '10rem' }} />
              <br />
              <br />
              <Card.Title>
                DIAMOND CONSULTORIA MIGRATORIA
              </Card.Title>
              <Card.Text>
                "Simplificando el derecho migratorio"
                <br />
                RNC: 132-301-048
                <br />
                <b>¡Busca tu Propiedad!</b>
              </Card.Text>
              <hr />
              <Card.Text>
                <Form onSubmit={handleSubmit}>
                  <Form.Group className="mb-3" controlId="formBasicAddress">
                    <Form.Label>Nombre completo</Form.Label>
                    <Form.Control
                      type="text"
                      name="nombre"
                      placeholder="Nombre completo"
                      value={userData.nombre}
                      onChange={handleChange}
                      required
                    />
                    <Form.Label>Telefono</Form.Label>
                    <Form.Control
                      type="text"
                      name="telefono"
                      placeholder="Telefono"
                      value={userData.telefono}
                      onChange={handleChange}
                      required
                    />
                    <Form.Label>Correo Electronico</Form.Label>
                    <Form.Control
                      type="email"
                      name="correo"
                      placeholder="joe@joe.me"
                      value={userData.correo}
                      onChange={handleChange}
                      required
                    />
                    <Form.Label>Ubicacion donde quiere la propriedad</Form.Label>
                    <Form.Control
                      type="text"
                      name="ubicacion"
                      placeholder="Ubicacion"
                      value={userData.ubicacion}
                      onChange={handleChange}
                    />
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="formBasicIngreso">
                    <Form.Label>Typo de propriedad</Form.Label>
                    <Form.Select placeholder="Typo de propriedad" name="propriedad" value={userData.propriedad} onChange={handleChange} required>
                      <option>Elige el typo de propriedad que buscas</option>
                      <option value="Apartamentos">Apartamentos</option>
                      <option value="Casas">Casas</option>
                      <option value="Villas">Villas</option>
                      <option value="Solares">Solares</option>
                      <option value="Fincas">Fincas</option>
                      <option value="Naves">Naves</option>
                      <option value="Oficinas">Oficinas</option>
                      <option value="Edificios">Edificios</option>
                      <option value="Penthouses">Penthouses</option>
                      <option value="Negocios">Negocios</option>
                    </Form.Select>
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="formBasicImigracion">
                    <Form.Label>Rango de precio</Form.Label>
                    <Form.Select placeholder="Rango de precio" name="precio" value={userData.precio} onChange={handleChange} required>
                      <option>Elige el precio que deseas</option>
                      <option value="RD$0 - RD$500,000">RD$0 - RD$500,000</option>
                      <option value="RD$500,000 - RD$1,000,000">RD$500,000 - RD$1,000,000</option>
                      <option value="RD$1,000,000 - RD$1,500,000">RD$1,000,000 - RD$1,500,000</option>
                      <option value="RD$1,500,000 - RD$2,000,000">RD$1,500,000 - RD$2,000,000</option>
                      <option value="RD$2,000,000 - RD$3,000,000">RD$2,000,000 - RD$3,000,000</option>
                      <option value="RD$3,000,000 - RD$5,000,000">RD$3,000,000 - RD$5,000,000</option>
                      <option value="RD$5,000,000 - RD$7,000,000">RD$5,000,000 - RD$7,000,000</option>
                      <option value="RD$7,000,000 - RD$10,000,000">RD$7,000,000 - RD$10,000,000</option>
                      <option value="RD$10,000,000 - RD$15,000,000">RD$10,000,000 - RD$15,000,000</option>
                      <option value="RD$15,000,000 - RD$25,000,000">RD$15,000,000 - RD$25,000,000</option>
                      <option value="RD$25,000,000 - RD$50,000,000">RD$25,000,000 - RD$50,000,000</option>
                      <option value="RD$50,000,000 - +">RD$50,000,000 - + </option>
                    </Form.Select>
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="formBasicImigracion">
                    <Form.Select placeholder="Razon" name="necesidad" value={userData.necesidad} onChange={handleChange} required>
                      <option>Comprar</option>
                      <option value="Comprar">Comprar</option>
                      <option value="Alquiler">Alquiler</option>
                      <option value="Alquiler amueblado">Alquiler amueblado</option>
                    </Form.Select>
                    <br />
                    <Form.Select placeholder="Habitacion" name="habitacion" value={userData.habitacion} onChange={handleChange} required>
                      <option>Habitaciones</option>
                      <option value="1 habitación">1 habitación</option>
                      <option value="2 habitaciones">2 habitaciones</option>
                      <option value="3 habitaciones">3 habitaciones</option>
                      <option value="4 habitaciones">4 habitaciones</option>
                      <option value="5 habitaciones">5 habitaciones</option>
                      <option value="6 habitaciones">6 habitaciones</option>
                      <option value="7 habitaciones">7 habitaciones</option>
                      <option value="8 habitaciones">8 habitaciones</option>
                      <option value="9 habitaciones">9 habitaciones</option>
                      <option value="10 habitaciones">10 habitaciones</option>
                    </Form.Select>
                    <br />
                    <Form.Select placeholder="Bano" name="bano" value={userData.bano} onChange={handleChange} required>
                      <option>Baños</option>
                      <option value="1+ baño">1+ baño</option>
                      <option value="2+ baños">2+ baños</option>
                      <option value="3+ baños">3+ baños</option>
                      <option value="4+ baños">4+ baños</option>
                      <option value="5+ baños">5+ baños </option>
                    </Form.Select>
                  </Form.Group>

                  <Button variant="primary" type="submit">
                    Enviar
                  </Button>
                </Form>
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default FormCitas;
