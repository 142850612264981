import React, { useEffect } from 'react';
import {
  Container, Row, Col, Card,
} from 'react-bootstrap';
import AOS from 'aos';
import 'aos/dist/aos.css';
import CulturaMision from '../utility/cultura.json';

function Cultura() {
  useEffect(() => {
    AOS.init();
  });
  return (
    <Container fluid>
      <Row>
        <Col
          className="portfolio"
          data-aos="slide-left"
          data-aos-easing="ease-out-cubic"
          data-aos-duration="2000"
        >
          { CulturaMision && CulturaMision.map((moto) => (
            <Card key={moto.id}>
              <div className="text-center">
                <Card.Img variant="top" src={moto.image} className="fluid" style={{ width: '45%' }} />
              </div>
              <Card.Body>
                <Card.Title className="text-uppercase">{moto.title}</Card.Title>
                <Card.Text>{moto.content}</Card.Text>
              </Card.Body>
            </Card>
          ))}
        </Col>
      </Row>
    </Container>
  );
}

export default Cultura;
