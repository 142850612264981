import React, { useEffect } from 'react';
import {
  Container, Row, Col, Card,
} from 'react-bootstrap';
import AOS from 'aos';
import 'aos/dist/aos.css';
import YouTube from 'react-youtube';
import ResidenciaList from '../utility/residencia.json';

function Residencia() {
  useEffect(() => {
    AOS.init();
  });

  const VideoOnready = (e) => {
    e.target.pauseVideo();
  };

  const opts = {
    playerVars: {
      autoplay: true,
    },
  };

  return (
    <Container style={{ backgroundColor: '#f8f8f8' }} fluid>
      <h1 className="heading">Residencia</h1>
      <hr className="divider" />
      <Row>
        <Col className="contact" sm md lg xl xxl xxxl>
          <div className="video-container">
            <YouTube
              videoId="HPW14WyJuBw"
              opts={opts}
              onReady={VideoOnready}
            />
          </div>
        </Col>
      </Row>
      <Row>
        <Col
          className="portfolio"
          sm
          md
          lg
          xl
          xxl
          xxxl
          data-aos="zoom-in"
          data-aos-duration="3000"
        >
          { ResidenciaList && ResidenciaList.map((res) => (
            <Card className="residencia-card pb-3" key={res.id}>
              <Card.Body>
                <Card.Title>{res.type}</Card.Title>
                <hr />
                <Card.Text>{res.description}</Card.Text>
              </Card.Body>
            </Card>
          ))}
        </Col>
      </Row>
    </Container>
  );
}

export default Residencia;
