import React from 'react';
import {
  Container, Row, Col, Image,
} from 'react-bootstrap';
import Legal from '../components/Legal';
import Abogados from '../components/Abogados';
import Legali from '../assets/asuntos.jpeg';

function Asuntos() {
  return (
    <Container fluid>
      <h1 className="text-center mt-5 pl-3 pr-3">OTROS ASUNTOS LEGALES</h1>
      <hr className="divider" />
      <Row>
        <Col className="contact image-home" sm md lg xl xxl xxxl>
          <Image
            src={Legali}
            style={{ width: '100%', height: '49vh' }}
            fluid
          />
        </Col>
      </Row>
      <Legal />
      <Abogados />
    </Container>
  );
}

export default Asuntos;
