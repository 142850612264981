import React from 'react';
import {
  Container, Row, Col, Image,
} from 'react-bootstrap';
import DominicanaText from '../components/Dominicana';
import Dominicana from '../assets/dominicana.jpeg';

function Mundandose() {
  return (
    <Container fluid>
      <h1 className="text-center mt-5">MUDÁNDOSE EN LA REPUBLICA DOMINICANA</h1>
      <hr className="divider" />
      <Row>
        <Col className="contact image-home" sm md lg xl xxl xxxl>
          <Image
            src={Dominicana}
            style={{ width: '100%', height: '49vh' }}
            fluid
          />
        </Col>
        <DominicanaText />
      </Row>
    </Container>
  );
}

export default Mundandose;
