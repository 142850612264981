/* eslint-disable no-alert */
/* eslint-disable no-useless-return */
/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ref, uploadBytesResumable } from 'firebase/storage';
import {
  Container, Row, Col, Card, Form, Button,
} from 'react-bootstrap';
import { v4 } from 'uuid';
import { storage } from '../utility/firebase';

function UploadFiles() {
  const navigate = useNavigate();
  const [imageUpload, setImageUpload] = useState(null);

  const handleChange = (e) => {
    setImageUpload(e.target.files[0]);
  };

  const handleUpload = (e) => {
    e.preventDefault();
    if (!imageUpload) {
      alert('elige una foto por favor');
    }

    const imageRef = ref(storage, `images/${imageUpload.name + v4()}`);
    uploadBytesResumable(imageRef, imageUpload).then(() => {
      alert('Informacion cargada con exito, gracias!');
    });
    navigate('/', { replace: true });
  };

  return (
    <Container>
      <Row>
        <Col
          className="form-container mt-2"
          sm
          md
          lg
          xl
          xxl
        >
          <Card className="passport-card" style={{ width: '38rem', height: '15rem' }}>
            <Card.Body>
              <Card.Title>Imagen de su pasaporte</Card.Title>
              <Card.Text>
                <Form>
                  <Form.Group className="mb-3" controlid="FormBasicImage">
                    <Form.Label>Carga la foto de la primera pagina de su pasaporte</Form.Label>
                    <Form.Control
                      type="file"
                      accept="image/*"
                      name="pasaporte"
                      onChange={handleChange}
                      required
                    />
                    <Form.Text className="text-muted">
                      Tus informaciones son privada, nunca van a publicarlos.
                    </Form.Text>
                  </Form.Group>

                  <Button
                    variant="primary"
                    type="submit"
                    onClick={handleUpload}
                  >
                    Cargar
                  </Button>
                </Form>
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default UploadFiles;
