import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import NavBar from './NavBar';
import Home from '../containers/Home';
import About from '../containers/About';
import Residencia from '../containers/Residencia';
import Litigio from '../containers/Litigio';
import Mundandose from '../containers/Mundandose';
import Apostillas from '../containers/Apostillas';
import Impuestos from '../containers/Impuestos';
import Asuntos from '../containers/Asuntos';
import Contact from '../containers/Contact';
import Citas from '../containers/Citas';
import Raices from '../containers/Raices';
import Footer from './Footer';
import '../styles/App.css';

function RoutesLink() {
  return (
    <Router>
      <NavBar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/nosotros" element={<About />} />
        <Route path="/residencia" element={<Residencia />} />
        <Route path="/litigio" element={<Litigio />} />
        <Route path="/mundandose" element={<Mundandose />} />
        <Route path="/apostillas" element={<Apostillas />} />
        <Route path="/impuestos" element={<Impuestos />} />
        <Route path="/asuntos-legales" element={<Asuntos />} />
        <Route path="/contacto" element={<Contact />} />
        <Route path="/citas" element={<Citas />} />
        <Route path="/raices" element={<Raices />} />
      </Routes>
      <Footer />
    </Router>
  );
}

export default RoutesLink;
