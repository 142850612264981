import React from 'react';
import {
  Container, Row, Col, Image,
} from 'react-bootstrap';
import Translate from '../assets/traduccion.jpeg';

function Traducciones() {
  return (
    <Container fluid>
      <Row>
        <Col className="nosotros mt-5" sm md lg xl xxl xxxl>
          <h2 className="text-center text-uppercase pr-3">Traducciones al español</h2>
          <p>
            La legislación de la Republica Dominicana prevé que para que un documento publico
            extranjero
            surta efecto en Republica Dominicana, es necesario que se presente debidamente
            legalizado por
            a representación consultar dominicana ubicada en el lugar en el que se expidió el
            documento legalizado . Cabe señalar  que deberán legalizarse todos aquellos documentos
            publico emitidos por países que no sean miembros de la convención de Haya sobre la
            Apostilla y que deban utilizarse en otro país no miembro de dicha convención,
            el primero debe de legalizar su documento, igualmente se realiza el procedimiento a
            la inversa.
            <br />
            <br />
            La legalización de firmas y /o sellos, contenidos en documentos público
            extranjeros, es un acto de certificación por medio del cual el funcionario consular
            certifica que la firma o sello (de un funcionario u oficina gubernamental o notarial)
            que se localiza dentro de su circunscripción, coincide con la forma o sello registrados
            en sus archivos, Al igual que en el caso de la apostilla, esta certificación nunca habrá
            de prejuzgar sobre el contenido del documento.
          </p>
        </Col>
        <Col className="nosotros mt-5" sm md lg xl xxl xxxl>
          <Image
            className="pl-3"
            src={Translate}
            alt="Diamond Consultoria Migratoria"
            style={{
              width: '100%',
              height: 'auto',
              borderRadius: '25px',
              marginBottom: '1rem',
            }}
            fluid
          />
        </Col>
      </Row>
    </Container>
  );
}

export default Traducciones;
