import React from 'react';
import TopFooter from './TopFooter';

function Footer() {
  return (
    <footer sm md lg xl xxl>
      <TopFooter />
      <span className="copyright">
        &copy;
        {` ${(new Date()).getFullYear()} All rights reserved | Built with love by ` }
        <a href="https://denislafontant.com" target="_blank" rel="noopener noreferrer">Denis Lafontant</a>
      </span>
    </footer>
  );
}

export default Footer;
