import React from 'react';
import {
  Container, Row, Col, Image,
} from 'react-bootstrap';
import YouTube from 'react-youtube';
import Legalizacion from '../components/Legalizacion';
import Traducciones from '../components/Traducion';
import Apostilla from '../assets/apostillas.jpeg';

function Apostillas() {
  const VideoOnready = (e) => {
    e.target.pauseVideo();
  };

  const opts = {
    playerVars: {
      autoplay: true,
    },
  };

  return (
    <Container fluid>
      <h1 className="text-center mt-5 pl-3 pr-3">APOSTILLAS, LEGALIZACIONES Y TRADUCCIONES</h1>
      <hr className="divider" />
      <Row>
        <Col sm md lg xl xxl xxxl>
          <div className="video-container">
            <YouTube
              videoId="8BT7alZBMUY"
              opts={opts}
              onReady={VideoOnready}
            />
          </div>
        </Col>
      </Row>
      <Row>
        <Col className="nosotros mt-5 pl-3 pr-3" sm md lg xl xxl xxxl>
          <h2 className="text-center text-uppercase">Apostillas</h2>
          <p>
            Con la convención de la Haya, por la que se suprime el requisito de legalización
            de los documentos públicos extranjeros, adoptada en la Haya, Países Bajos,
            el 5 de octubre de 1961, mejor conocido como Convención de la apostilla, exime de
            legalización a los documentos públicos que deben ser presentados en sus territorios
            exigiendo como única formalidad la fijación de la apostilla.
          </p>
          <br />
          <br />
          <p>
            Este proceso consiste en certificar que la firma y el sello de un documento público
            ueron puestos por una autoridad en uso de sus facultades . La apostilla, al igual que
            la autenticación (legalización), únicamente certifica que la firma o sello que muestra
            el documento fue emitido por un funcionario publico en ejercicio de sus funciones,
            pero no certifica la validez del contenido mismo
          </p>
        </Col>
        <Col className="nosotros mt-5" sm md lg xl xxl xxxl>
          <Image
            src={Apostilla}
            alt="Diamond Consultoria Migratoria"
            style={{
              width: '100%',
              height: 'auto',
              borderRadius: '25px',
              marginBottom: '1rem',
            }}
            fluid
          />
        </Col>
      </Row>
      <Legalizacion />
      <Traducciones />
    </Container>
  );
}

export default Apostillas;
