import React from 'react';
import { Link } from 'react-router-dom';
import {
  Container, Row, Col, Button,
} from 'react-bootstrap';
import YouTube from 'react-youtube';

function Impuestos() {
  const VideoOnready = (e) => {
    e.target.pauseVideo();
  };

  const opts = {
    playerVars: {
      autoplay: true,
    },
  };

  return (
    <Container>
      <h1 className="text-center mt-5 pl-3 pr-3">IMPUESTOS,CORPORATIVO, NEGOCIOS Y BIENES RAÍCES</h1>
      <hr className="divider" />
      <Row>
        <Col sm md lg xl xxl xxxl>
          <div className="video-container">
            <YouTube
              videoId="wA3LE6JDYxA"
              opts={opts}
              onReady={VideoOnready}
            />
          </div>
        </Col>
      </Row>
      <Row>
        <Col className="nosotros mt-5 pl-3 pr-3" sm md lg xl xxl xxxl>
          <p>
            Para extranjeros que deseen invertir en República Dominicana y necesiten
            asesoría legal en la compra de bienes raíces, corporativos, impuestos,
            inversión extranjera directa e indirecta, y asuntos relacionados. Brindamos soluciones
            de “back office”, ofreciendo servicios de nómina, contabilidad, asesoría
            legal corporativa,
            contrataciones, y demás necesidades que su empresa pueda tener en República Dominicana.
            Nos esforzamos por brindar un asesoramiento legal de calidad a nuestros clientes,
            garantizando un apoyo total y teniendo siempre en cuenta sus intereses.
            <br />
            <br />
            <div className="text-center">
              <Button variant="dark" className="citas-button">
                <Link to="/raices">BIEN RAICES</Link>
              </Button>
            </div>
            <br />
            <br />
            Muchos extranjeros y expatriados que desean venir o ya viven en Repùblica Dominicana
            enfrentan un
            sistema legal diferente en su país de origen y pueden pensar que establecer un negocio
            en la República Dominicana y obtener los permisos necesarios es un proceso
            extremadamente complicado. Sin embargo, podemos ayudarlo a navegar a través del sistema
            legal dominicano e identificar los beneficios y facilidades que pueden ayudar a que su
            idea de negocio prospere.
          </p>
        </Col>
      </Row>
    </Container>
  );
}

export default Impuestos;
