import React from 'react';
import {
  Container, Row, Col,
} from 'react-bootstrap';
import YouTube from 'react-youtube';

//  https://www.youtube.com/watch?v=taRG0GhmgRY

function Video() {
  const VideoOnready = (e) => {
    e.target.pauseVideo();
  };

  const opts = {
    playerVars: {
      autoplay: true,
    },
  };

  return (
    <Container>
      <Row>
        <Col sm md lg xl xxl xxxl>
          <div className="video-container">
            <YouTube
              videoId="taRG0GhmgRY"
              opts={opts}
              onReady={VideoOnready}
            />
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default Video;
