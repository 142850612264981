import React, { useEffect } from 'react';
import {
  Container, Row, Col, Card,
} from 'react-bootstrap';
import AOS from 'aos';
import 'aos/dist/aos.css';
import ServiceList from '../utility/servicios.json';

function Servicios() {
  useEffect(() => {
    AOS.init();
  });

  return (
    <Container style={{ backgroundColor: '#f8f8f8' }} fluid>
      <h1 className="heading">SERVICIOS</h1>
      <hr className="divider" />
      <Row>
        <Col
          className="portfolio"
          sm
          md
          lg
          xl
          xxl
          xxxl
          data-aos="zoom-in"
          data-aos-duration="3000"
        >
          { ServiceList && ServiceList.map((serv) => (
            <Card className="service-card" key={serv.id}>
              <Card.Body>
                <Card.Img src={serv.image} alt="Diamond Consultoria Migratoria" className="fluid" style={{ width: '45%' }} />
                <Card.Title>{serv.title}</Card.Title>
                <Card.Text>{serv.content}</Card.Text>
              </Card.Body>
            </Card>
          ))}
        </Col>
      </Row>
    </Container>
  );
}

export default Servicios;
