import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import CarouselSlider from '../components/Carousel';
import Intro from '../components/Intro';
import Nosotros from '../components/Nosotros';
import Compromiso from '../components/Compromiso';
import Cultura from '../components/Cultura';
import Servicios from '../components/Servicios';

function Home() {
  return (
    <Container fluid>
      <Row>
        <Col sm md lg xl xxl>
          <CarouselSlider />
          <Intro />
          <Nosotros />
          <Compromiso />
          <Cultura />
          <Servicios />
        </Col>
      </Row>
    </Container>
  );
}

export default Home;
