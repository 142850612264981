import React from 'react';
import {
  Container, Row, Col,
} from 'react-bootstrap';

function DominicanaText() {
  return (
    <Container>
      <Row>
        <Col className="mt-3 mb-5" sm md lg xl xxl xxxl>
          Sabemos que mudarse a un nuevo país puede ser una experiencia desalentadora.
          No se trata solamente de cambiarse a un nuevo lugar, sino poder estar en ese
          nuevo lugar y llamarlo hogar. Gracias a nuestros acuerdos de cooperación con
          agencias aduanales en Repùblica Dominicana, podemos ofrecerle asistencia con respecto a la
          logística y el trasporte de sus pertenencias personales y muebles, así como para
          cualquier vehículo que puede llevar con usted. Nuestros servicios de reubicacion
          también incluyen la asistencia en aduanas para aquellos extranjeros que se
          desplazan fuera de la Repùblica Dominicana.
          <br />
          <br />
          También proporcionamos asistencia a los extranjeros que ya viven en Republica
          Dominicana o que
          estén dispuestos a venir a nuestros país. Podemos ser capaces de ayudar con diversos
          temas como el alquiler de apartamentos, comprar una propiedad en Repùblica Dominicana
          , los servicios
          de contratación (teléfono, televisión vía satélite, conexión a internet, entre otros),
          asistencia para la inscripción de sus hijos en escuelas publicas y privadas y más.
        </Col>
      </Row>
    </Container>
  );
}

export default DominicanaText;
