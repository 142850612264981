import React from 'react';
import {
  Container, Row, Col, Image,
} from 'react-bootstrap';
import Staff from '../assets/staff.jpeg';

function Nosotros() {
  return (
    <Container style={{ backgroundColor: '#f8f8f8' }} fluid>
      <h1 className="heading">SOBRE NOSOTROS</h1>
      <hr className="divider" />
      <Row>
        <Col className="nosotros" sm md lg xl xxl xxxl>
          Diamond Consultoría Migratoria es un despacho de abogados establecido en Ciudad de
          Santo Domingo, enfocado en resolver problemas legales que pueda enfrentar el extranjero
          en República Dominicana. Somos especialistas en derecho migratorio, trámites migratorios
          e inversión extranjera, brindando asesoría y representación legal en materia migratoria
          tanto en español, frances, como en inglés.
          <br />
          <br />
          La Firma es administrada por un equipo de abogados altamente experimentados,
          asistiendo a nuestros clientes a través de la complejidad del entramado legal en
          República Dominicana. Nuestra Firma ha evolucionado y se ha convertido en un despacho
          legal sofisticado. Nos apasiona brindar servicios migratorios y legales de calidad,
          representando a ejecutivos internacionales, empresas públicas y privadas, inversionistas,
          trabajadores extranjeros, talentos deportivos y a toda persona que atraviesa el
          procedimiento migratorio dominicano en cada una de sus etapas. Nuestra meta es cumplir
          con nuestro lema “simplificando el derecho migratorio”.
          <br />
          <br />
          Nuestra práctica legal abarca exclusivamente el derecho migratorio dominicano:
          residencia, visas de inversionista y solvencia económica, defensa migratoria,
          migración humanitaria, así como otras áreas de especialidad que nuestros clientes
          requieren, ofreciendo servicios de asesoría y representación legal en materia de
          apostillado y legalización, negocios, impuestos e inversión extranjera.
        </Col>
        <Col className="nosotros hide" sm md lg xl xxl xxxl>
          <div className="image-home text-center">
            <Image
              src={Staff}
              alt="Diamond Consultoria Migratoria"
              style={{
                width: '60%',
                height: 'auto',
                borderRadius: '25px',
                marginBottom: '1rem',
              }}
              fluid
            />
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default Nosotros;
